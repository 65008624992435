import { Box, Button, Grid, IconButton, Modal, Notification, Typography, MuiIcon } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { StepStatus } from "components/advance/StepStatus"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import { compose, withHooks, withStores } from "enhancers"
import { Field } from "pages/main/claim-detail/form-field"
import { Link, useHistory } from "react-router-dom"
import styled from "styled-components"
import Theme from "theme/custom"
import { employeeIsHr, gql, paths } from "utils/helper"
import { ApproveModal } from "./approve-modal"
import RejectModal from "./reject-modal"
import { isEmpty } from "lodash"
import { DefaultPreviewComponent } from "pages/main/claim-detail/form-field/preview/Default"

const relation = {
  Child: "บุตร",
  Spouse: "คู่สมรส",
  MOM: "มารดา",
  DAD: "บิดา",
}

const DetailStatusContainer = styled("div")`
  position: relative;
  background-color: ${Theme.colors["Primary/Hover"]};
  height: auto;
  padding: 16px;
`

const ClaimDetail = styled("div")`
  position: relative;
  background: ${Theme.colors["White / White"]};
  height: auto;
  padding: 16px;
  box-shadow: 4px 0px 8px ${Theme.colors["Text/Background"]};
`

const CloseButton = styled(IconButton)`
  color: ${Theme.colors["White / White"]};
  padding: 0px;
`

const Title = styled("div")`
  margin-top: 24px;
`

const TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ActionButton = styled(Button)`
  width: 100%;
`

const DetailBox = styled(Box)`
  margin-top: 16px;
`

const FlexBox = styled(Box)<{ mb?: string; mt?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
`

const FrontBox = styled(Box)<{ mr?: string }>`
  min-width: 100px;
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
`

const FooterContainer = styled("div")`
  padding: 16px;
  background-color: ${Theme.colors["White / White"]};
`

const WarningIcon = styled(MuiIcon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${Theme.colors["Warning"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const LightBlueBox = styled(Box)`
  background: ${Theme.colors["White / White"]};
  border-radius: 4px;
  padding: 8px;
  margin-top: 16px;
`

const ApprovalPageComponent = (props: any) => (
  <>
    {props.defaultTransferDate && props.isLoaded && (
      <ApproveModal
        title={props.title}
        isOpen={props.isApproveModalOpen}
        onClose={props.handleCloseApproveModal}
        onConfirm={props.handleConfirmApprove}
        amount={props.requestAmount(props.claimRequest)}
        isInsurance={props.isInsurance}
        defaultTransferDate={props.defaultTransferDate}
      />
    )}

    <RejectModal
      title={props.title}
      isOpen={props.isRejectModalOpen}
      onClose={props.handleCloseRejectModal}
      onConfirm={props.handleConfirmReject}
      amount={props.requestAmount(props.claimRequest)}
      rejectReasonsOptions={props.rejectReasonsOptions}
    />
    <Box style={{ backgroundColor: Theme.colors["Primary/Hover"] }}>
      <Box
        minHeight={200}
        // minWidth={357}
        maxWidth={752}
        overflow="hidden"
        mx="auto"
      >
        <DetailStatusContainer>
          <TopBox>
            <Typography variant="h2" color="White / White">
              รายละเอียดการเบิก
            </Typography>
            {/* <Link to="/claim-requests">
              <CloseButton>
                <MuiIcon name="Close" style={{ fontSize: "24px" }} />
              </CloseButton>
            </Link> */}
            <CloseButton onClick={props.handleClosePage}>
              <MuiIcon name="Close" style={{ fontSize: "24px" }} />
            </CloseButton>
          </TopBox>
          <DetailBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  ผู้เบิก
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.createdBy}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  สังกัด
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.department ?? "-"}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  กลุ่มพนักงาน
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.employeeGroup ?? "-"}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  เบอร์โทร
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.tel ?? "-"}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  อีเมล
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.officialMailId}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  เลขอ้างอิง
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.claimId}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  วันที่เบิก
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {dayjs(props.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </FlexBox>
            <FlexBox mb="16px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  สถานะ
                </Typography>
              </FrontBox>
              <StepStatus
                claimStatus={props.claimStatus}
                workflows={props.workflows}
                workflowStep={props.workflowStep}
              />
            </FlexBox>
            <FlexBox>
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  แก้ไขล่าสุด
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {dayjs(props.updatedAt).format("DD/MM/YYYY")}
              </Typography>
            </FlexBox>
          </DetailBox>
          {props.claimStatus === EnumClaimRequestStatus.APPROVED && props.transferDate && (
            <LightBlueBox>
              <FlexBox mb="4px">
                <FrontBox mr="8px">
                  <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                    โอนเข้า
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                  {props.isInsurance ? "บัญชีส่วนตัว" : "บัญชีเงินเดือน"}
                </Typography>
              </FlexBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="subtitle1" color="Text/Gray Preview"></Typography>
                </FrontBox>
                <Typography variant="subtitle1" color="Text/Gray Preview">
                  {props.isInsurance
                    ? "ส่งเอกสารการเคลมให้กับบริษัทประกันเมื่อ " +
                      dayjs(props.transferDate).format("DD/MM/YYYY") +
                      " เงินจะเข้าบัญชีภายใน 15-20 วันทำการ"
                    : "ภายในวันที่ " + dayjs(props.transferDate).format("DD/MM/YYYY")}
                </Typography>
              </FlexBox>
              {props.monthForTax && (
                <FlexBox mb="4px" mt="2px">
                  <FrontBox mr="8px">
                    <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                      เดือนที่หักภาษี
                    </Typography>
                  </FrontBox>
                  <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                    {props.monthForTax}
                  </Typography>
                </FlexBox>
              )}
            </LightBlueBox>
          )}
          {props.claimStatus === EnumClaimRequestStatus.REJECTED && (
            <LightBlueBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                    เหตุผลที่ปฏิเสธ
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color={Theme.colors["Text/Primary Text"]}>
                  {props.rejectedReason ?? "-"}
                </Typography>
              </FlexBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                    หมายเหตุ
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color={Theme.colors["Text/Primary Text"]}>
                  {props.remark ?? "-"}
                </Typography>
              </FlexBox>
            </LightBlueBox>
          )}
        </DetailStatusContainer>
      </Box>
    </Box>
    <Box
      minHeight={200}
      // minWidth={357}
      maxWidth={752}
      overflow="hidden"
      mx="auto"
    >
      <ClaimDetail>
        {props.currentUserIsHr && props.isInstead && (
          <>
            <WarningBox mt="8px" mb="24px">
              <WarningIcon name="Warning" color={Theme.colors["Text/Primary Text"]}></WarningIcon>
              <Typography variant="body2" color={Theme.colors["Text/Primary Text"]}>
                ทำรายการแทนพนักงานคนอื่น
              </Typography>
            </WarningBox>
          </>
        )}
        <Box mt="8px">
          <ClaimCard
            title={props.title}
            type={props.type}
            name={props.name}
            approvedRequest={props.approvedCount}
            totalAmount={props.approvedAmount}
            icon={props.icon}
            iconColor={props.iconColor}
            chipTitleColor={props.chipTitleColor}
            chipBackgroundColor={props.chipBackgroundColor}
            fullSize
            displayOnly
          />
        </Box>
        <Title>
          <Typography variant="h3">ตรวจสอบรายละเอียด</Typography>
        </Title>
        <Grid container style={{ paddingTop: 16 }}>
          <Grid item xs={12} key="select-employee">
            <Typography variant="body1" color="Text/Gray Preview">
              ผู้ขอเบิก
            </Typography>
            <Field isPreview={true} component="SELECT" label="" name="requester" value={props.requesterValues} />
          </Grid>
          {props.claimForDisplay && (
            <Grid item xs={12} key="select-relations" style={{ paddingTop: 16 }}>
              <DefaultPreviewComponent component="INPUT" label="เบิกให้" value={props.claimForDisplay} />
            </Grid>
          )}
          {props.inputs?.map((input: any, ind: number) => (
            <Grid item xs={12} key={`${input.name} ${ind}`} style={{ paddingTop: 16 }}>
              <Typography variant="body1" color="Text/Gray Preview">
                {input.title}
              </Typography>
              <Field
                isPreview={true}
                value={input.value}
                component={input.type}
                label=""
                name={input.name}
                options={input.options}
                unit={input.unit}
              />
            </Grid>
          ))}
        </Grid>

        {props.documents.length > 0 && (
          <UploadDocumentCard
            documents={props.documents}
            onChange={props.handleFilesChange}
            canDelete={false}
            isPreview={true}
            mb="8px"
          />
        )}
      </ClaimDetail>
      {props.showFooter && (
        <FooterContainer>
          <Typography variant="h3" color="Text/Black2">
            ผลการพิจารณา
          </Typography>
          <FlexBox mt="16px" mb="8px">
            <ActionButton
              variant="outlined"
              onClick={props.handleOpenRejectModal}
              mr="16px"
              disabled={props.disabledRejectButton}
            >
              ไม่อนุมัติ
            </ActionButton>
            <ActionButton
              onClick={props.handleOpenApproveModal}
              variant="contained"
              disabled={props.disabledApproveButton}
            >
              อนุมัติ
            </ActionButton>
          </FlexBox>
        </FooterContainer>
      )}
    </Box>
  </>
)

const API = {
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetail(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        remark
        rejectedReason
        transferDate
        canceled
        currentWorkflow
        approvedCount
        approvedAmount
      }
    }
  `,
  APPROVE_REQUEST: gql`
    mutation APPROVE_REQUEST(
      $requestId: String!
      $isApprove: Boolean!
      $createdBy: String!
      $remark: String
      $transferDate: DateTime
      $seq: Int!
      $rejectedReason: String
    ) {
      approveClaimRequest(
        input: {
          requestId: $requestId
          isApprove: $isApprove
          createdBy: $createdBy
          remark: $remark
          transferDate: $transferDate
          seq: $seq
          rejectedReason: $rejectedReason
        }
      ) {
        workflowSeq
        id
        type
        status
        config
        info
        createdAt
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const { currentUserInfo } = props
    const { useParams, useQuery, useCallback, useEffect, useLazyQuery, useState, useMutation, useMemo } = hooks
    const browserHistory = useHistory()
    const { id } = useParams()
    const { data, refetch, loading: detailLoading } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
    })
    const [approveRequest, { error: approveError }] = useMutation(API.APPROVE_REQUEST)
    const [disabledApproveButton, setDisabledApproveButton] = useState(false)
    const [disabledRejectButton, setDisabledRejectButton] = useState(false)

    const [isApproveModalOpen, setIsApproveModalOpen] = useState(false)
    const [isRejectModalOpen, setIsRejectModalOpen] = useState(false)
    const [workflowStep, setWorkflowStep] = useState(0)

    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const approveParams = useMemo(
      () => ({
        requestId: id,
        createdBy: currentUser?.id,
      }),
      [id, currentUser],
    )

    const goBack = useCallback(() => {
      if (browserHistory.length > 1) {
        browserHistory.goBack()
      } else {
        browserHistory.push("/claim-requests")
      }
    }, [browserHistory])

    const nextActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetail.currentWorkflow + 1,
      )
      return currentWorkflow
    }, [data])

    const currentUserCanAction = useMemo(() => {
      return nextActionWorkflow?.possibleApproverIds.includes(currentUser?.id)
    }, [nextActionWorkflow, currentUser])

    const requestRejected = useMemo(
      () =>
        data?.claimRequestHistoryDetail.workflowSeq.filter(
          (flow: any) => flow.status === EnumClaimRequestStatus.REJECTED,
        ).length > 0,
      [data],
    )

    const requestApproved = useMemo(() => data?.claimRequestHistoryDetail.status === EnumClaimRequestStatus.APPROVED, [
      data,
    ])

    const showFooter = useMemo(() => {
      if (requestApproved || requestRejected || !currentUserCanAction) return false
      return true
    }, [requestApproved, requestRejected, currentUserCanAction])

    const approvalSection = useMemo(() => {
      if (requestRejected)
        return {
          label: "ปฏิเสธเมื่อ",
          approvalAt: dayjs(data?.claimRequestHistoryDetail.approvalAt).format("DD/MM/YYYY"),
          detailLabel: "เหตุผล",
          detail: data?.claimRequestHistoryDetail.rejectedReason,
        }
      if (requestApproved)
        return {
          label: "อนุมัติเมื่อ",
          approvalAt: dayjs(data?.claimRequestHistoryDetail.approvalAt).format("DD/MM/YYYY"),
          detailLabel: "โอนเข้า",
          detail: "บัญชีเงินเดือน",
          sub: `(${dayjs(data?.claimRequestHistoryDetail.transferDate).format("DD/MM/YYYY")})`,
        }
      return undefined
    }, [requestRejected, data, requestApproved])

    const monthForTax = useMemo(() => {
      // Work around logic for SCG
      // TODO: Remove this logic after fix includeTax on existing claim_requests completed
      if (data?.claimRequestHistoryDetail.transferDate) {
        if (
          ["medical_fee", "dental_fee", "health_examination_fee", "health_examination_fee2"].includes(
            data?.claimRequestHistoryDetail?.config.name,
          )
        ) {
          return null
        } else {
          console.log(
            "[TEST] data?.claimRequestHistoryDetail?.transferDate",
            data?.claimRequestHistoryDetail?.transferDate,
          )
          const date = dayjs(data.claimRequestHistoryDetail.transferDate)
          let month = date.month()
          let year = date.year()
          if (date.date() > 16) {
            month = (month + 1) % 12
            if (month === 0) {
              year++
            }
          }
          const taxMonth = new Date(year, month, 1).toLocaleDateString("th-TH", { month: "long", year: "numeric" })
          return taxMonth
        }
      }

      // Original logic
      if (data?.claimRequestHistoryDetail.transferDate && data?.claimRequestHistoryDetail?.config.includeTax) {
        const date = dayjs(data.claimRequestHistoryDetail.transferDate)
        let month = date.month()
        let year = date.year()
        if (date.day() > 16) {
          month = (month + 1) % 12
          if (month === 0) {
            year++
          }
        }
        const taxMonth = new Date(year, month + 1, 1).toLocaleDateString("th-TH", { month: "long", year: "numeric" })
        return taxMonth
      }
    }, [
      data?.claimRequestHistoryDetail.transferDate,
      data?.claimRequestHistoryDetail?.config.includeTax,
      data?.claimRequestHistoryDetail?.config.name,
    ])

    const employee = useMemo(() => data?.claimRequestHistoryDetail.employee, [data])

    const hasFile = useMemo(() => data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) > 0, [
      data,
    ])

    const isInstead = useMemo(
      () => data?.claimRequestHistoryDetail.employee.id !== data?.claimRequestHistoryDetail.createdBy.id,
      [data],
    )

    const defaultTransferDate = useMemo(() => {
      if (data?.claimRequestHistoryDetail.transferDate) return dayjs(data?.claimRequestHistoryDetail.transferDate)
      return dayjs()
    }, [data])

    const requestAmount = useCallback((history: any) => {
      let amount = 0
      history?.info?.inputs.forEach((input: any) => {
        if (input.name === history.info.amountFieldName) {
          amount = input.value
        }
      })
      return amount
    }, [])

    const isInsurance = useMemo(
      () =>
        data?.claimRequestHistoryDetail.info.type === "ประกันกลุ่ม" ||
        data?.claimRequestHistoryDetail.info.type === "ประกันสังคม",
      [data],
    )

    const handleClosePage = useCallback(() => {
      goBack()
      // paths.claimRequestsPath().push()
      // console.log("🚀 ~ browserHistory", browserHistory)

      // if (browserHistory.length > 1) {
      //   // Get the previous entry
      //   // const previousPathname = browserHistory.entries[browserHistory.length - 2].pathname

      //   // Check the previous pathname
      //   // if (previousPathname === "/some/path") {
      //   //   // Do something
      //   // }

      //   // Go back
      //   browserHistory.goBack()
      // } else {
      //   // There is no previous entry, navigate to a default page
      //   browserHistory.push("/claim-requests")
      // }
    }, [goBack])

    const handleCloseErrorModal = useCallback(
      (confirmProps: any) => {
        confirmProps.close()
        // paths.claimRequestsPath().push()
        goBack()
        setDisabledApproveButton(false)
        setDisabledRejectButton(false)
      },
      [goBack],
    )

    const handleConfirmApprove = useCallback(
      async (date?: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          let params: {
            isApprove: boolean
            requestId: string
            createdBy: string
            seq: number
            transferDate?: string
          } = {
            isApprove: true,
            seq: nextActionWorkflow.seq,
            ...approveParams,
          }

          if (date) params.transferDate = date
          await approveRequest({ variables: { ...params } })
          // @ts-ignore
          Notification.success("บันทึกข้อมูลแล้ว")
          // paths.claimRequestsPath().push()
          goBack()
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        } catch (e: any) {
          if (e) {
            // @ts-ignore
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb="16px">
                    <MuiIcon fontSize="large" color={Theme.colors["Warning"]} name="Warning"></MuiIcon>
                  </Box>
                  <Box mb="16px">
                    <Typography variant="body1" color="Text/Black2">
                      {e.message}
                    </Typography>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ตกลง",
              okButtonVariant: "contained",
              buttonWidth: "100%",
              onOk: handleCloseErrorModal,
            })
          }
        }
      },
      [handleCloseErrorModal, approveRequest, approveParams, nextActionWorkflow, goBack],
    )
    const handleOpenApproveModal = useCallback(() => {
      const workflowSeq = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflowSeq = data?.claimRequestHistoryDetail.currentWorkflow + 1
      const currentWorkflow = workflowSeq.find((workflow: any) => workflow.seq === currentWorkflowSeq)
      if (currentWorkflow?.workflow.submitTransferDate) {
        setIsApproveModalOpen(true)
      } else {
        const title = data?.claimRequestHistoryDetail.info.title
        const amount = Number(requestAmount(data?.claimRequestHistoryDetail).replace(",", ""))

        const amountDisplay = isNaN(amount) ? 0 : amount
        // @ts-ignore
        Modal.open({
          children: (
            <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
              <Typography variant="h3" color={Theme.colors["Text/Primary Text"]}>
                ยอมรับการเบิกสวัสดิการ ?
              </Typography>
              <Box mb="4px">
                <Typography variant="body1" color={Theme.colors["Text/Placeholder"]}>
                  อนุมัติการเบิก
                </Typography>
              </Box>
              <Box mb="16px">
                <Typography variant="h6" color={Theme.colors["Primary/Primary Text"]} isCurrency>
                  {title + " " + amountDisplay + " บาท"}
                </Typography>
              </Box>
            </div>
          ),
          onOk: () => {
            handleConfirmApprove()
            // @ts-ignore
            Modal.close()
          },
          okButtonLabel: "อนุมัติการเบิก",
          okButtonVariant: "contained",
          cancelButtonLabel: "ปิดหน้าต่างนี้",
          cancelButtonVariant: "outlined",
        })
      }
      // if (currentUserIsHr) {
      //   setIsApproveModalOpen(true)
      // } else {
      //   handleConfirmApprove()
      // }
    }, [setIsApproveModalOpen, handleConfirmApprove, data, requestAmount])

    const handleCloseApproveModal = useCallback(() => {
      setIsApproveModalOpen(false)
    }, [])

    const handleOpenRejectModal = useCallback(() => {
      setIsRejectModalOpen(true)
    }, [])
    const handleCloseRejectModal = useCallback(() => {
      setIsRejectModalOpen(false)
    }, [])
    const handleConfirmReject = useCallback(
      async (remark: string, rejectedReason: string) => {
        try {
          setDisabledApproveButton(true)
          setDisabledRejectButton(true)
          await approveRequest({
            variables: {
              isApprove: false,
              seq: nextActionWorkflow.seq,
              remark,
              rejectedReason,
              ...approveParams,
            },
          })
          // @ts-ignore
          Notification.success("บันทึกข้อมูลแล้ว")
          // paths.claimRequestsPath().push()
          goBack()
          setDisabledApproveButton(false)
          setDisabledRejectButton(false)
        } catch (e: any) {
          if (e) {
            // @ts-ignore
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb="16px">
                    <MuiIcon fontSize="large" color={Theme.colors["Warning"]} name="Warning"></MuiIcon>
                  </Box>
                  <Box mb="16px">
                    <Typography variant="body1" color="Text/Black2">
                      {e.message}
                    </Typography>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ตกลง",
              okButtonVariant: "contained",
              buttonWidth: "100%",
              onOk: handleCloseErrorModal,
            })
          }
        }
      },
      [handleCloseErrorModal, approveRequest, approveParams, nextActionWorkflow, goBack],
    )

    const isLoaded = useMemo(() => !detailLoading, [detailLoading])
    const rejectReasonsOptions = useMemo(() => {
      if (nextActionWorkflow) {
        const workflow = nextActionWorkflow.workflow
        if (workflow?.role === currentUser.role) {
          const rejectReasons = workflow.rejectReasons || []
          return rejectReasons.map((reason: { description: string }) => ({
            label: reason.description,
            value: reason.description,
          }))
        }
      }
    }, [nextActionWorkflow, currentUser.role])

    const claimForDisplay = useMemo(() => {
      const claimFor = data?.claimRequestHistoryDetail.info.claimFor

      if (claimFor && claimFor !== "Self") {
        const displayName = isEmpty(claimFor.nameTh.trim())
          ? relation[claimFor.relationship as keyof typeof relation]
          : claimFor.nameTh
        return displayName
      }
      return undefined
    }, [data?.claimRequestHistoryDetail.info.claimFor])

    useEffect(() => {
      refetch()
      if (data?.claimRequestHistoryDetail.workflowSeq) {
        const workflows = data?.claimRequestHistoryDetail.workflowSeq
        const last = workflows[workflows.length - 1]
        if (nextActionWorkflow) {
          const nextActionIndex = workflows.findIndex(
            (wf: any) => JSON.stringify(wf) === JSON.stringify(nextActionWorkflow),
          )
          setWorkflowStep(nextActionIndex)
        } else if (last) {
          setWorkflowStep(workflows.length)
        }
      }
    }, [data, nextActionWorkflow, refetch])

    return {
      handleClosePage,

      handleOpenApproveModal,
      handleCloseApproveModal,
      isApproveModalOpen,
      handleConfirmApprove,

      handleOpenRejectModal,
      handleCloseRejectModal,
      isRejectModalOpen,
      handleConfirmReject,

      claimId: data?.claimRequestHistoryDetail.referenceId,
      createdBy:
        data?.claimRequestHistoryDetail.createdBy.firstName + " " + data?.claimRequestHistoryDetail.createdBy.lastName,
      createdAt: data?.claimRequestHistoryDetail.createdAt,
      updatedAt: data?.claimRequestHistoryDetail.updatedAt,
      inputs: data?.claimRequestHistoryDetail.info.inputs,
      documents: data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) || [],
      title: data?.claimRequestHistoryDetail.info.title,
      subTitle: data?.claimRequestHistoryDetail.info.subTitle,
      icon: data?.claimRequestHistoryDetail.info.icon,
      iconColor: data?.claimRequestHistoryDetail.info.iconColor,
      chipTitleColor: data?.claimRequestHistoryDetail.info.chipTitleColor,
      chipBackgroundColor: data?.claimRequestHistoryDetail.info.chipBackgroundColor,
      type: data?.claimRequestHistoryDetail.info.type,
      name: data?.claimRequestHistoryDetail.info.name,
      approvedAmount: data?.claimRequestHistoryDetail.approvedAmount,
      approvedCount: data?.claimRequestHistoryDetail.approvedCount,
      claimStatus: data?.claimRequestHistoryDetail.status,
      currentUser,

      workflows: data?.claimRequestHistoryDetail.workflowSeq || [],
      workflowStep: requestRejected ? (workflowStep - 1 < 0 ? 0 : workflowStep - 1) : workflowStep,

      showFooter,

      requesterValues: employee ? employee.firstName + " " + employee.lastName : undefined,
      approvalSection,
      hasFile,
      requestAmount,
      claimRequest: data?.claimRequestHistoryDetail,
      transferDate: data?.claimRequestHistoryDetail.transferDate || null,
      remark: data?.claimRequestHistoryDetail.remark || null,
      rejectedReason: data?.claimRequestHistoryDetail.rejectedReason || null,
      currentUserIsHr,
      isInstead,
      isInsurance,
      error: approveError,
      defaultTransferDate,

      disabledApproveButton,
      disabledRejectButton,
      isLoaded,

      claimForDisplay,
      employeeGroup: data?.claimRequestHistoryDetail.employee.title,
      officialMailId: data?.claimRequestHistoryDetail.employee.officialMailId,
      department: data?.claimRequestHistoryDetail.employee.department,
      tel: data?.claimRequestHistoryDetail.employee.tel,
      monthForTax,
      rejectReasonsOptions,
    }
  }),
)

const ApprovalPage = enhancer(ApprovalPageComponent)

export default ApprovalPage
