import { gql } from "@apollo/client"
import { removeToken } from "api"
import FilterAltIcon from "assets/icon/filter_alt.svg"
import { Box, Button, IconButton, Typography, Icon } from "components"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import { compose, withHooks, withStores } from "enhancers"
import { msalInstance } from "index"
import { useCallback } from "react"
import { Link } from "react-router-dom"
import paths from "routes/paths"
import userStore from "stores/userStore"
import styled from "styled-components"
import Theme from "theme/custom"
import { employeeIsHr } from "utils/helper"

const TitleContainer = styled(Box)<Partial<TitleProps>>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: ${(props) => (props.centered ? "center" : "flex-start")};
  /* padding: 0px 16px 0px 0px; */
  width: 100%;
`

const ListIconContainer = styled("div")`
  /* position: absolute; */
  top: 6px;
  right: 4px;
`

const CustomIconList = styled(Icon)`
  font-size: 20px;
`

const TitleText = styled.div<{ haveBackButton?: boolean }>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: ${(props) => (props.haveBackButton ? "14px 16px 14px 0px" : "14px 0px 14px 16px")};
`

const ListAmount = styled("div")`
  position: absolute;
  background-color: ${Theme.colors["Text/Primary Pink"]};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 12px;
  right: 10px;
  height: 12px;
  width: fit-content;
  padding: 0px 3.5px;
  z-index: 2;
`

const FilteredMark = styled("div")`
  position: absolute;
  background-color: ${Theme.colors["Text/Primary Pink"]};
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 16px;
  right: 8px;
  height: 8px;
  padding: 0px 3.5px;
  z-index: 2;
`

const LogoutButton = styled(Button)`
  position: absolute;
  top: 12px;
  left: 12px;
  padding: 4px;
  height: auto;
  z-index: 1000;
  color: ${Theme.colors["White / White"]};
  text-decoration: underline;
  &:hover {
    opacity: 0.8;
    color: ${Theme.colors["White / White"]};
    text-decoration: underline;
  }
`

interface TitleProps {
  title: string
  centered?: boolean
  titleColor: keyof typeof Theme.colors

  onListClickTo?: string
  listAmount: number
  showList: boolean

  onBack?: () => void
  showDashBoardIcon?: boolean
  showHomeIcon?: boolean
  showFilter?: boolean
  openFilterModal?: void
  filtered?: boolean

  showLogout?: boolean
  handleClickLogout: () => void

  isHr: boolean
}

const TitleComponent = (props: TitleProps) => {
  const {
    title,
    centered,
    titleColor,
    onListClickTo,
    onBack,
    showHomeIcon,
    showList,
    showDashBoardIcon,
    showFilter,
    openFilterModal,
    listAmount,
    filtered,
    handleClickLogout,
    showLogout,
    isHr,
  } = props

  return (
    <>
      {title !== "" ? (
        <TitleContainer
          // minWidth={357}
          maxWidth={784}
          centered={centered}
        >
          {showLogout && (
            <LogoutButton variant="text" onClick={handleClickLogout}>
              Logout
            </LogoutButton>
          )}
          {onBack && (
            <IconButton onClick={onBack} style={{ color: titleColor }}>
              <CustomIconList name="ChevronLeft" />
            </IconButton>
          )}
          <TitleText haveBackButton={onBack ? true : false}>
            <Typography variant="h2" color={titleColor} noWrap>
              {title}
            </Typography>
          </TitleText>
          {/* TODO: Uncomment when connect download report to marketplace-report-api completed  */}
          {/* {showDashBoardIcon && (
            <Link to="/dashboard">
              <IconButton style={{ color: titleColor }}>
                <Icon name="report" />
              </IconButton>
            </Link>
          )} */}
          {showDashBoardIcon && isHr && (
            <Link to="/dashboard">
              <IconButton style={{ color: titleColor }}>
                <Icon name="report" />
              </IconButton>
            </Link>
          )}
          {showHomeIcon && (
            <Link to="/home">
              <IconButton style={{ color: titleColor }}>
                <Icon name="HouseOutlined" color={Theme.colors["White / White"]} width={24} height={24} />
              </IconButton>
            </Link>
          )}
          {/* {onListClickTo && listAmount > 0 && (
            <Box>
              <Link to={onListClickTo}>
                <ListAmount>
                  <Typography
                    variant="body1"
                    color="White / White"
                    style={{ fontSize: "8px", fontWeight: 400, lineHeight: "16px" }}
                  >
                    {listAmount > 99 ? "99+" : listAmount}
                  </Typography>
                </ListAmount>
                <ListIconContainer>
                  <IconButton style={{ color: titleColor }}>
                    <Icon name="DescriptionOutlined" fontSize="small" />
                  </IconButton>
                </ListIconContainer>
              </Link>
            </Box>
          )} */}
          {onListClickTo && isHr && (
            <Box>
              <Link to={onListClickTo}>
                {listAmount > 0 && (
                  <ListAmount>
                    <Typography
                      variant="body1"
                      color="White / White"
                      style={{ fontSize: "8px", fontWeight: 400, lineHeight: "16px" }}
                    >
                      {listAmount > 99 ? "99+" : listAmount}
                    </Typography>
                  </ListAmount>
                )}
                <ListIconContainer>
                  <IconButton style={{ color: titleColor }}>
                    <Icon name="DescriptionOutlined" fontSize="small" />
                  </IconButton>
                </ListIconContainer>
              </Link>
            </Box>
          )}
          {showFilter && (
            <ListIconContainer>
              {filtered && <FilteredMark />}
              <IconButton style={{ color: titleColor }} onClick={openFilterModal}>
                <img src={FilterAltIcon} style={{ width: "auto", height: "auto" }} alt="" />
              </IconButton>
            </ListIconContainer>
          )}
        </TitleContainer>
      ) : (
        <></>
      )}
    </>
  )
}

const API = {
  GET_CLAIM_REQUEST_LIST: gql`
    query GET_CLAIM_REQUEST_LIST($status: String!) {
      claimRequestList(input: { status: $status }) {
        id
        status
      }
    }
  `,
}

const TitleEnhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    const {
      currentUserInfo,
      onListClickTo,
      showLogout = false,
      showDashBoardIcon = false,
      showHomeIcon = false,
    } = props
    const { useState, useLazyQuery, useEffect, useMemo } = hooks
    const currentUser = currentUserInfo?.employee
    const isHr = employeeIsHr(currentUser.role)

    const [waitingRequestCount, setWaitingRequestCount] = useState(0)
    const filteredStatus = EnumClaimRequestStatus.WAITING
    const [fetchWaitingRequests] = useLazyQuery(API.GET_CLAIM_REQUEST_LIST, {
      variables: { status: filteredStatus },
      onCompleted(data: any) {
        setWaitingRequestCount(data.claimRequestList.length)
      },
      fetchPolicy: "network-only",
    })

    const handleClickLogout = useCallback(() => {
      const account = msalInstance.getActiveAccount()
      if (account && currentUserInfo) {
        msalInstance.logoutRedirect({
          account,
          onRedirectNavigate: (url) => {
            userStore.setCurrentUser(null)
            removeToken()
            msalInstance.setActiveAccount(null)
            return true
          },
        })
      } else {
        // TODO : Remove test logout button
        userStore.setCurrentUser(null)
        removeToken()
        window.location.href = paths.signInPath()
      }
    }, [currentUserInfo])

    const isShowLogoutButton = useMemo(() => {
      const account = msalInstance.getActiveAccount()
      // TODO : Remove test logout button
      const SHOW_LOGOUT = localStorage.getItem("SHOW_LOGOUT")
      if (SHOW_LOGOUT) return showLogout
      if (account && currentUserInfo) return showLogout
      return false
    }, [currentUserInfo, showLogout])

    useEffect(() => {
      if (onListClickTo) {
        fetchWaitingRequests()
      }
    }, [onListClickTo, fetchWaitingRequests])

    return {
      ...props,
      currentUser,
      listAmount: waitingRequestCount,
      handleClickLogout,
      showLogout: isShowLogoutButton,
      showDashBoardIcon,
      showHomeIcon,
      isHr,
    }
  }),
)

export const Title = TitleEnhancer(TitleComponent)
