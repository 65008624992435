import { compose, withHooks, withStores } from "enhancers"
import { Box, Button, Divider, Grid, IconButton, Modal, Notification, Typography } from "components"
import { ClaimCard } from "components/advance/ClaimCard"
import { StepStatus } from "components/advance/StepStatus"
import { UploadDocumentCard } from "components/advance/UploadDocumentCard"
import { MuiIcon } from "components/common/MuiIcon"
import { EnumClaimRequestStatus } from "constants/enums/claim-request-status"
import dayjs from "dayjs"
import styled from "styled-components"
import Theme from "theme/custom"
import { employeeIsHr, gql, paths } from "utils/helper"
import { Field } from "../../claim-detail/form-field"
import { DefaultPreviewComponent } from "pages/main/claim-detail/form-field/preview/Default"
import { isEmpty } from "lodash"
import { useHistory } from "react-router-dom"

const relation = {
  Child: "บุตร",
  Spouse: "คู่สมรส",
  MOM: "มารดา",
  DAD: "บิดา",
}

const DetailStatusContainer = styled("div")`
  position: relative;
  /* background-color: ${Theme.colors["Primary/Line"]}; */
  background-color: ${Theme.colors["Primary/Line"]};
  height: auto;
  padding: 16px;
`

const ClaimDetail = styled("div")`
  position: relative;
  background: ${Theme.colors["White / White"]};
  height: auto;
  padding: 16px;
`

const CloseButton = styled(IconButton)`
  color: ${Theme.colors["White / White"]};
  padding: 0px;
`

const Title = styled("div")`
  margin-top: 24px;
`

const TopBox = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DetailBox = styled(Box)`
  margin-top: 16px;
`

const FlexBox = styled(Box)<{ mb?: string }>`
  display: flex;
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const FrontBox = styled(Box)<{ mr?: string }>`
  min-width: 100px;
  margin-right: ${(props) => (props.mr ? props.mr : "0px")};
`

const LightBlueBox = styled(Box)`
  background: ${Theme.colors["White / White"]};
  border-radius: 8px;
  padding: 8px;
  margin-top: 16px;
`

const WarningIcon = styled(MuiIcon)`
  font-size: 16px;
  margin-right: 4px;
`

const WarningBox = styled(Box)<{ mt?: string; mb?: string }>`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0px 8px;
  height: 32px;
  background-color: ${Theme.colors["Warning"]};
  border-radius: 8px;
  margin-top: ${(props) => (props.mt ? props.mt : "0px")};
  margin-bottom: ${(props) => (props.mb ? props.mb : "0px")};
`

const HistoryDetailPage = (props: any) => (
  <>
    <Box style={{ backgroundColor: Theme.colors["Primary/Line"] }}>
      <Box
        minHeight={200}
        // minWidth={357}
        maxWidth={752}
        overflow="hidden"
        mx="auto"
      >
        <DetailStatusContainer>
          <TopBox>
            <Typography variant="h2" color="White / White">
              รายละเอียดการเบิก
            </Typography>
            <CloseButton onClick={props.handleClose}>
              <MuiIcon name="Close" style={{ fontSize: "24px" }} />
            </CloseButton>
          </TopBox>
          <DetailBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  ผู้เบิก
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.createdBy}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  สังกัด
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.department ?? "-"}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  กลุ่มพนักงาน
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.employeeGroup ?? "-"}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  เบอร์โทร
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.tel ?? "-"}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  อีเมล
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.officialMailId}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  เลขอ้างอิง
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {props.claimId}
              </Typography>
            </FlexBox>
            <FlexBox mb="8px">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  วันที่เบิก
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {dayjs(props.createdAt).format("DD/MM/YYYY")}
              </Typography>
            </FlexBox>
            <FlexBox mb="16px" minWidth={325} maxWidth={730} overflow="hidden">
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  สถานะ
                </Typography>
              </FrontBox>
              <StepStatus
                claimStatus={props.claimStatus}
                workflows={props.workflows}
                workflowStep={props.workflowStep}
                canceled={props.canceled}
              />
            </FlexBox>
            <FlexBox>
              <FrontBox mr="16px">
                <Typography variant="body1" color="White / White">
                  แก้ไขล่าสุด
                </Typography>
              </FrontBox>
              <Typography variant="body1" color="White / White">
                {dayjs(props.updatedAt).format("DD/MM/YYYY")}
              </Typography>
            </FlexBox>
          </DetailBox>
          {props.claimStatus === EnumClaimRequestStatus.WAITING && !props.withoutCancel && !props.checkApprovalStatus && (
            <>
              <Divider style={{ marginTop: "16px", marginBottom: "16px" }} />
              <Button
                onClick={props.onClickCancelClaim}
                width="100%"
                color="primary"
                style={{ border: "none", background: "none", textDecoration: "underline" }}
              >
                <MuiIcon name="DeleteOutlineRounded" style={{ fontSize: "20px", marginRight: "6px" }} />
                ยกเลิกการเบิก
              </Button>
            </>
          )}
          {props.claimStatus === EnumClaimRequestStatus.APPROVED && props.transferDate && (
            <LightBlueBox>
              <FlexBox mb="4px">
                <FrontBox mr="8px">
                  <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                    โอนเข้า
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                  {props.isInsurance ? "บัญชีส่วนตัว" : "บัญชีเงินเดือน"}
                </Typography>
              </FlexBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="subtitle1" color="Text/Gray Preview"></Typography>
                </FrontBox>
                <Typography variant="subtitle1" color="Text/Gray Preview">
                  {props.isInsurance
                    ? "ส่งเอกสารการเคลมให้กับบริษัทประกันเมื่อ " +
                      dayjs(props.transferDate).format("DD/MM/YYYY") +
                      " เงินจะเข้าบัญชีภายใน 15-20 วันทำการ"
                    : "ภายในวันที่ " + dayjs(props.transferDate).format("DD/MM/YYYY")}
                </Typography>
              </FlexBox>
              {props.monthForTax && (
                <FlexBox mb="4px" mt="2px">
                  <FrontBox mr="8px">
                    <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                      เดือนที่หักภาษี
                    </Typography>
                  </FrontBox>
                  <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                    {props.monthForTax}
                  </Typography>
                </FlexBox>
              )}
            </LightBlueBox>
          )}
          {props.claimStatus === EnumClaimRequestStatus.REJECTED && (
            <LightBlueBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                    เหตุผลที่ปฏิเสธ
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color={Theme.colors["Text/Primary Text"]}>
                  {props.rejectedReason ?? "-"}
                </Typography>
              </FlexBox>
              <FlexBox>
                <FrontBox mr="8px">
                  <Typography variant="h4" color={Theme.colors["Primary/Primary Text"]}>
                    หมายเหตุ
                  </Typography>
                </FrontBox>
                <Typography variant="h4" color={Theme.colors["Text/Primary Text"]}>
                  {props.remark ?? "-"}
                </Typography>
              </FlexBox>
            </LightBlueBox>
          )}
        </DetailStatusContainer>
      </Box>
    </Box>
    <Box
      minHeight={200}
      // minWidth={357}
      maxWidth={752}
      overflow="hidden"
      mx="auto"
    >
      <ClaimDetail>
        {props.currentUserIsHr && props.isInstead && !props.withoutCancel && (
          <>
            <WarningBox mt="8px" mb="24px">
              <WarningIcon name="Warning" color={Theme.colors["Text/Primary Text"]}></WarningIcon>
              <Typography variant="body2" color={Theme.colors["Text/Primary Text"]}>
                ทำรายการแทนพนักงานคนอื่น
              </Typography>
            </WarningBox>
          </>
        )}
        <Box mt="8px">
          <ClaimCard
            title={props.title}
            type={props.type}
            name={props.name}
            approvedRequest={props.approvedCount}
            totalAmount={props.approvedAmount}
            icon={props.icon}
            iconColor={props.iconColor}
            chipTitleColor={props.chipTitleColor}
            chipBackgroundColor={props.chipBackgroundColor}
            fullSize
            displayOnly
          />
        </Box>
        <Title>
          <Typography variant="h3">ตรวจสอบรายละเอียด</Typography>
        </Title>

        <Grid container style={{ paddingTop: 16 }}>
          <Grid item xs={12} key="select-employee">
            <Typography variant="body1" color="Text/Gray Preview">
              ผู้ขอเบิก
            </Typography>
            <Field isPreview={true} component="SELECT" label="" name="requester" value={props.requesterValues} />
          </Grid>
          {props.claimForDisplay && (
            <Grid item xs={12} key="select-relations" style={{ paddingTop: 16 }}>
              <DefaultPreviewComponent component="INPUT" label="เบิกให้" value={props.claimForDisplay} />
            </Grid>
          )}
          {props.inputs?.map((input: any, ind: number) => (
            <Grid item xs={12} key={`${input.name} ${ind}`} style={{ paddingTop: 16 }}>
              <Typography variant="body1" color="Text/Gray Preview">
                {input.title}
              </Typography>
              <Field
                isPreview={true}
                value={input.value}
                component={input.type}
                label=""
                name={input.name}
                options={input.options}
                unit={input.unit}
              />
            </Grid>
          ))}
        </Grid>
        {props.documents.length > 0 && (
          <UploadDocumentCard
            documents={props.documents}
            onChange={props.handleFilesChange}
            canDelete={false}
            isPreview={true}
          />
        )}
      </ClaimDetail>
    </Box>
  </>
)

const API = {
  GET_HISTORY_DETAIL: gql`
    query GET_HISTORY_DETAIL($id: String!) {
      claimRequestHistoryDetail(id: $id) {
        workflowSeq
        id
        type
        status
        config
        info
        employeeId
        employee
        createdAt
        updatedAt
        createdBy
        referenceId
        remark
        rejectedReason
        transferDate
        canceled
        currentWorkflow
        approvedCount
        approvedAmount
      }
    }
  `,
  CANCEL_REQUEST: gql`
    mutation CANCEL_REQUEST($id: String!) {
      cancelClaimRequest(id: $id) {
        id
        status
      }
    }
  `,
}

const enhancer = compose(
  withStores((stores: any) => ({
    currentUserInfo: stores.userStore.currentUser,
  })),
  withHooks((props: any, hooks: any) => {
    // CASE: handle-filter-from-url
    const browserHistory = useHistory()
    // END-CASE: handle-filter-from-url
    const { useParams, useQuery, useState, useEffect, useMutation, useMemo, useCallback } = hooks
    const { currentUserInfo, withoutCancel } = props
    const currentUser = useMemo(() => {
      return currentUserInfo.employee
    }, [currentUserInfo])
    const { id } = useParams()
    const { data, refetch } = useQuery(API.GET_HISTORY_DETAIL, {
      variables: { id },
    })
    const [cancelRequest] = useMutation(API.CANCEL_REQUEST)

    const employee = useMemo(() => data?.claimRequestHistoryDetail.employee, [data])
    const handleClose = useCallback(() => {
      if (withoutCancel) {
        // paths.dashboardPath().push()

        // CASE: handle-filter-from-url
        if (browserHistory.length > 1) {
          // Go back
          browserHistory.goBack()
        } else {
          // There is no previous entry, navigate to a default page
          browserHistory.push("/dashboard")
        }
        // END-CASE: handle-filter-from-url
      } else {
        paths.historyPath().push()
      }
    }, [withoutCancel])

    const requestRejected = useMemo(
      () =>
        data?.claimRequestHistoryDetail.workflowSeq.filter(
          (flow: any) => flow.status === EnumClaimRequestStatus.REJECTED,
        ).length > 0,
      [data],
    )

    const hasFile = useMemo(() => data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) > 0, [
      data,
    ])

    const currentUserIsHr = useMemo(() => employeeIsHr(currentUser.role), [currentUser])

    const isInstead = useMemo(
      () => data?.claimRequestHistoryDetail.employee.id !== data?.claimRequestHistoryDetail.createdBy.id,
      [data],
    )

    const requestAmount = useCallback((history: any) => {
      let amount = 0
      history.info.inputs.forEach((input: any) => {
        if (input.name === history.info.amountFieldName) {
          amount = input.value
        }
      })
      return amount
    }, [])

    const handleCloseErrorModal = useCallback((confirmProps: any) => {
      confirmProps.close()
      paths.historyPath().push()
    }, [])

    const handleCancelClaim = useCallback(
      async (confirmProps: any) => {
        confirmProps.close()
        try {
          await cancelRequest({ variables: { id } })
          confirmProps.close()
          paths.historyPath().push()
          // @ts-ignore
          Notification.success("ยกเลิกคำร้องแล้ว")
        } catch (e: any) {
          if (e) {
            // @ts-ignore
            Modal.alert({
              className: "deleteConFirmModal",
              title: "",
              children: (
                <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                  <Box mb="16px">
                    <MuiIcon fontSize="large" color={Theme.colors["Warning"]} name="Warning"></MuiIcon>
                  </Box>
                  <Box mb="16px">
                    <Typography variant="body1" color="Text/Black2">
                      {e.message}
                    </Typography>
                  </Box>
                </Box>
              ),
              okButtonLabel: "ตกลง",
              okButtonVariant: "contained",
              buttonWidth: "100%",
              onOk: handleCloseErrorModal,
            })
          }
        }
      },
      [cancelRequest, id, handleCloseErrorModal],
    )

    const onClickCancelClaim = useCallback(() => {
      // @ts-ignore
      Modal.confirm({
        className: "deleteConFirmModal",
        title: "",
        children: (
          <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
            <Box mb="16px">
              <Typography variant="h3" color="Text/Black2">
                ต้องการยกเลิกการเบิกสวัสดิการ ?
              </Typography>
            </Box>
            <Box mb="4px">
              <Typography variant="body1" color="Text/Gray Preview">
                ยกเลิกการเบิก
              </Typography>
            </Box>
            <Box mb="16px">
              <Typography variant="h6" color={Theme.colors["Primary/Primary Text"]}>
                {data?.claimRequestHistoryDetail.info.title +
                  " " +
                  requestAmount(data?.claimRequestHistoryDetail) +
                  " บาท"}
              </Typography>
            </Box>
          </Box>
        ),
        okButtonLabel: "ยืนยัน",
        okButtonVariant: "contained",
        // okButtonColor: "primary",
        cancelButtonLabel: "ปิดหน้าต่างนี้",
        cancelButtonVariant: "outlined",
        // cancelButtonColor: "primary",
        buttonWidth: 147,
        onOk: handleCancelClaim,
      })
    }, [handleCancelClaim, data, requestAmount])

    const isInsurance = useMemo(
      () =>
        data?.claimRequestHistoryDetail.info.type === "ประกันกลุ่ม" ||
        data?.claimRequestHistoryDetail.info.type === "ประกันสังคม",
      [data],
    )

    const nextActionWorkflow = useMemo(() => {
      const workflows = data?.claimRequestHistoryDetail.workflowSeq
      const currentWorkflow = workflows?.find(
        (flow: any) => flow.seq === data?.claimRequestHistoryDetail.currentWorkflow + 1,
      )
      return currentWorkflow
    }, [data])

    const [workflowStep, setWorkflowStep] = useState(0)

    useEffect(() => {
      refetch()
      if (data?.claimRequestHistoryDetail.workflowSeq) {
        const workflows = data?.claimRequestHistoryDetail.workflowSeq
        const last = workflows[workflows.length - 1]
        if (nextActionWorkflow) {
          const nextActionIndex = workflows.findIndex(
            (wf: any) => JSON.stringify(wf) === JSON.stringify(nextActionWorkflow),
          )
          setWorkflowStep(nextActionIndex)
        } else if (last) {
          setWorkflowStep(workflows.length)
        }
      }
    }, [data, nextActionWorkflow, refetch])

    const monthForTax = useMemo(() => {
      // Work around logic for SCG
      // TODO: Remove this logic after fix includeTax on existing claim_requests completed
      if (data?.claimRequestHistoryDetail.transferDate) {
        if (
          ["medical_fee", "dental_fee", "health_examination_fee", "health_examination_fee2"].includes(
            data?.claimRequestHistoryDetail?.config.name,
          )
        ) {
          return null
        } else {
          const date = dayjs(data.claimRequestHistoryDetail.transferDate)
          let month = date.month()
          let year = date.year()
          if (date.date() > 16) {
            month = (month + 1) % 12
            if (month === 0) {
              year++
            }
          }
          const taxMonth = new Date(year, month, 1).toLocaleDateString("th-TH", { month: "long", year: "numeric" })
          return taxMonth
        }
      }

      // Original logic
      if (data?.claimRequestHistoryDetail.transferDate && data?.claimRequestHistoryDetail?.config.includeTax) {
        const date = dayjs(data.claimRequestHistoryDetail.transferDate)
        let month = date.month()
        let year = date.year()
        if (date.day() > 16) {
          month = (month + 1) % 12
          if (month === 0) {
            year++
          }
        }
        const taxMonth = new Date(year, month + 1, 1).toLocaleDateString("th-TH", { month: "long", year: "numeric" })
        return taxMonth
      }
    }, [
      data?.claimRequestHistoryDetail.transferDate,
      data?.claimRequestHistoryDetail?.config.includeTax,
      data?.claimRequestHistoryDetail?.config.name,
    ])

    const claimForDisplay = useMemo(() => {
      const claimFor = data?.claimRequestHistoryDetail.info.claimFor
      if (claimFor && claimFor !== "Self") {
        const displayName = isEmpty(claimFor.nameTh.trim())
          ? relation[claimFor.relationship as keyof typeof relation]
          : claimFor.nameTh
        return displayName
      }
      return undefined
    }, [data?.claimRequestHistoryDetail.info.claimFor])

    return {
      handleClose,
      createdAt: data?.claimRequestHistoryDetail.createdAt,
      updatedAt: data?.claimRequestHistoryDetail.updatedAt,
      inputs: data?.claimRequestHistoryDetail.info.inputs,
      documents: data?.claimRequestHistoryDetail.info.documents.filter((doc: any) => doc.file) || [],
      claimId: data?.claimRequestHistoryDetail.referenceId,
      createdBy:
        data?.claimRequestHistoryDetail.createdBy.firstName + " " + data?.claimRequestHistoryDetail.createdBy.lastName,
      requesterValues: employee ? employee.firstName + " " + employee.lastName : undefined,

      workflows: data?.claimRequestHistoryDetail.workflowSeq || [],
      workflowStep: requestRejected ? (workflowStep - 1 < 0 ? 0 : workflowStep - 1) : workflowStep,
      hasFile,
      title: data?.claimRequestHistoryDetail.info.title,
      subTitle: data?.claimRequestHistoryDetail.info.subTitle,
      icon: data?.claimRequestHistoryDetail.info.icon,
      iconColor: data?.claimRequestHistoryDetail.info.iconColor,
      chipTitleColor: data?.claimRequestHistoryDetail.info.chipTitleColor,
      chipBackgroundColor: data?.claimRequestHistoryDetail.info.chipBackgroundColor,
      type: data?.claimRequestHistoryDetail.info.type,
      name: data?.claimRequestHistoryDetail.info.name,
      approvedAmount: data?.claimRequestHistoryDetail?.approvedAmount,
      approvedCount: data?.claimRequestHistoryDetail?.approvedCount,
      claimStatus: data?.claimRequestHistoryDetail.status,
      transferDate: data?.claimRequestHistoryDetail.transferDate || null,
      remark: data?.claimRequestHistoryDetail.remark || null,
      rejectedReason: data?.claimRequestHistoryDetail.rejectedReason || null,
      onClickCancelClaim,
      currentUserIsHr,
      isInstead,
      isInsurance,
      canceled: data?.claimRequestHistoryDetail.canceled,
      withoutCancel,
      checkApprovalStatus: data?.claimRequestHistoryDetail.workflowSeq.some((e: any) => e.status === "APPROVED"),

      claimForDisplay,
      employeeGroup: data?.claimRequestHistoryDetail.employee.title,
      officialMailId: data?.claimRequestHistoryDetail.employee.officialMailId,
      department: data?.claimRequestHistoryDetail.employee.department,
      tel: data?.claimRequestHistoryDetail.employee.tel,
      monthForTax,
    }
  }),
)

export default enhancer(HistoryDetailPage)
